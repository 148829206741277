<template>
    <div class="customer-credit">
        <div class="credit-evaluation-form-box">
            <div class="width-half">
                <p>期次</p>
                <!-- <p>{{ getCreditInitData.credit_no }}</p> -->
                <p>
                    <el-select
                        @change="changeSelect"
                        v-model="periodsValue"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </p>
            </div>
            <div class="width-half">
                <p>客户编号</p>
                <p>{{ getCreditInitData.client_no }}</p>
            </div>
            <div class="width-half">
                <p>客户名称</p>
                <p>{{ getCreditInitData.name }}</p>
            </div>
            <div class="width-half">
                <p>统一社会信用代码</p>
                <p>{{ getCreditInitData.license_code }}</p>
            </div>
            <div class="width-half">
                <p>客户性质</p>
                <p>{{ getCreditInitData.nature_name }}</p>
            </div>
            <div class="width-half">
                <p>客户等级</p>
                <p>{{ getCreditInitData.level_name }}</p>
            </div>
            <div class="width-half">
                <p>合同数量</p>
                <p>{{ getCreditInitData.contract_count }}</p>
            </div>
            <div class="width-half">
                <p>资信评价时间</p>
                <p>{{ getCreditInitData.modified }}</p>
            </div>
            <div class="width-half">
                <p>资信评价部门</p>
                <p>{{ getCreditInitData.dep_name }}</p>
            </div>
            <div class="width-half">
                <p>资信评价人</p>
                <p>{{ getCreditInitData.modifier }}</p>
            </div>
            <div class="width-half">
                <p>客户资信等级</p>
                <p>
                    <el-input readonly v-model="khzxdj"></el-input>
                </p>
            </div>
            <div class="width-half">
                <p>综合评分</p>
                <p>
                    <el-input readonly v-model="totalScoreTwo"></el-input>
                </p>
            </div>
        </div>
        <div class="credit-evaluation-form-message">
            <div class="message-header">
                <p>合同评价情况</p>
            </div>
            <div class="message-header message-header-two">
                <h1>合同编号</h1>
                <p>工程名称</p>
                <p>合同评价分值</p>
                <p>评分明细</p>
                <p>综合评分权重</p>
            </div>
            <div class="message-content">
                <div class="message-content-div" v-for="(item, index) in getCreditInitItem" :key="index">
                    <h1>{{ item.contract_number }}</h1>
                    <!-- <p class="overflowHidden">
                        {{ item.engineering_name }}
                    </p> -->
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.engineering_name"
                        placement="top"
                    >
                        <p class="overflowHidden">
                            {{ item.engineering_name }}
                        </p>
                    </el-tooltip>
                    <p>{{ item.eva_score }}</p>
                    <p class="looker">
                        <span @click="setEvaluateRule(item)">查看</span>
                    </p>
                    <p>
                        <el-input @change="changeWeight" v-model="item.weight"></el-input>%
                    </p>
                </div>
                <div class="message-content-div">
                    <h1>备注</h1>
                    <p class="beizhuinput">
                        <el-input v-model="beizhu"></el-input>
                    </p>
                </div>
            </div>
            <div class="message-bottom-button" v-if="!viewOnly">
                <el-button @click="saveMessage" type="primary">
                    保存
                </el-button>
                <el-button @click="handleCancel">
                    取消
                </el-button>
            </div>
        </div>
        <!-- <evaluateWindow v-if="showEvaluateWindow" :current-data="currentData" @closeWindow="closeWindow"></evaluateWindow> -->
    </div>
</template>

<script>
import store from '@/store';
export default {
    data() {
        return {
            beizhu: '', // 备注
            nowTime: '', // 资信评价时间
            totalScoreTotal: '', // 合计值
            showEvaluateWindow: false, // 是否显示评价弹窗
            currentData: {}, // 当前选中数据
            totalScoreTwo: '',
            khzxdj: '',
            getCreditInitData: {},
            getCreditInitItem: [],
            // 期次
            periodsValue: '',
            // 只读
            viewOnly: false,
            // 期次选项
            options: [],
            // 客户资信id
            client_id: '',
        };
    },
    props: {
        extr: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: {},
        },
    },
    mounted() {
        if (this.extr.client_id) {
            this.client_id = this.extr.client_id;
        }
        if (this.extr.creditNo) {
            this.periodsValue = this.extr.creditNo;
        }
        this.nowTime = this.timeFormat(new Date());
        this.getCreditInit();
        // 初始化期次
        this.creditNoInit();
    },
    methods: {
        // 关闭弹窗
        closeWindow() {
            this.showEvaluateWindow = false;
        },
        // 点击保存
        saveMessage() {
            if (this.totalScoreTotal !== 100) {
                this.$message.error('请输入正确的权重');
            } else {
                this.addCreditContract();
            }
        },
        // 保存
        addCreditContract() {
            const obj = {
                credit_no: this.getCreditInitData.credit_no,
                client_no: this.getCreditInitData.client_no,
                client_id: this.getCreditInitData.client_id,
                name: this.getCreditInitData.name,
                license_code: this.getCreditInitData.license_code,
                eva_score: this.totalScoreTwo,
                credit: this.khzxdj,
                remarks: this.beizhu,
                items: this.getCreditInitItem,
            };
            this.$axios
                .post('/interfaceApi/sale/credit_manager/credit_client', obj)
                .then(() => {
                    window.hideToast(1);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$message.success('保存成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 初始化期次
        async creditNoInit() {
            const res = await this.getCreditNo();
            if (res) {
                this.options = res;
                if (this.extr.creditNo) {
                    this.periodsValue = this.extr.creditNo;
                } else {
                    this.periodsValue = res[0];
                }
            }
        },
        // 选择期次
        changeSelect() {
            this.getCreditInit();
        },
        // 时间转化
        timeFormat(date) {
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            let h = date.getHours(); // 时
            let mi = date.getMinutes(); // 分
            let s = date.getSeconds(); // 秒
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            h = h > 9 ? h : '0' + h;
            mi = mi > 9 ? mi : '0' + mi;
            s = s > 9 ? s : '0' + s;
            return `${y}-${m}-${d} ${h}:${mi}:${s}`;
        },
        // 未配置时，获取初始化信息
        getCreditInit() {
            this.$axios
                .get(`/interfaceApi/sale/credit_manager/credit_client/${this.extr.client_no}/${this.periodsValue}`)
                .then(res => {
                    this.getCreditInitData = res;
                    this.getCreditInitItem = res.items;
                    this.beizhu = res.remarks;
                    if (res.id) {
                        this.changeWeight();
                    }
                    this.viewOnly = res.viewOnly;
                    if (this.extr.QueryString) {
                        if (this.extr.QueryString === 'viewOnly') {
                            this.viewOnly = true;
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 综合评分计算
        changeWeight() {
            let singleNumber = 0; // 单项值
            let weights = 0; // 权重
            let scores = 0; // 评分
            let ride = 0;
            let numbers = 0;
            this.getCreditInitItem.forEach(item => {
                numbers += Number(item.weight) * 1000; // 权重合计
                weights = Number(item.weight) * 100; // 权重
                scores = Number(item.eva_score) * 100;
                ride = scores * weights / 1000000;
                singleNumber += ride;
            });
            this.totalScoreTotal = numbers / 1000;
            this.totalScoreTwo = singleNumber.toFixed(2);
            const zhpf = Number(this.totalScoreTwo);
            if (zhpf >= 9) {
                this.khzxdj = 'A';
            } else if (zhpf < 9 && zhpf >= 8) {
                this.khzxdj = 'B';
            } else if (zhpf < 8 && zhpf >= 7) {
                this.khzxdj = 'C';
            } else if (zhpf < 7 && zhpf >= 6) {
                this.khzxdj = 'D';
            } else if (zhpf < 6) {
                this.khzxdj = 'E';
            }
        },
        handleCancel() {
            window.hideToast(1);
        },
        // 设置合同规则
        setEvaluateRule(item) {
            this.$toast(
                {
                    title: true,
                    text: '合同资信评价',
                    type: 'eject',
                    width: '12rem',
                    height: '9rem',
                    t_url: 'customerManagement/contractEvaluation',
                    viewPosition: 'view',
                    extr: {
                        creditNo: this.getCreditInitData.credit_no,
                        contract_number: item.contract_number,
                        QueryString: 'viewOnly',
                    },
                }
            );
            this.currentData = item;
        },
        // 拉取期次数据
        getCreditNo() {
            return this.$axios.get('/interfaceApi/sale/credit_manager/client_creditno/' + this.client_id);
        },
    },
};

</script>
<style lang='stylus' scoped>
::-webkit-scrollbar
    width: 3px /*高宽分别对应横竖滚动条的尺寸*/;
    height: 10px;
.customer-credit
    padding .2rem
    .credit-evaluation-form-box
        width 100%
        display flex
        flex-wrap: wrap;
        input::-webkit-input-placeholder
            font-size .16rem
        .width-half
            width 50%
            display flex
            height .45rem
            border-bottom 1px solid #ccc
            border-left 1px solid #ccc
            &:nth-child(1)
                border-top 1px solid #ccc
            &:nth-child(2)
                border-top 1px solid #ccc
            &:nth-child(even)
                border-right 1px solid #ccc
            p:first-child
                min-width 2.4rem
                max-width 2.4rem
                display flex
                align-items center
                font-size .16rem
                border-right 1px solid #ccc
                background #F6F8FC
                color #092d8e
                justify-content center
            p:last-child
                flex 1
                display flex
                font-size .16rem
                margin-left .2rem
                align-items center
            .el-input
                width 100% !important
                height .43rem
                .el-input__inner
                    height .43rem
                    border none
                    font-size .16rem
                    padding-left 0
            .el-select
                width 100%
        .width-all
            width 100%
            border-bottom 1px solid #ccc
            display flex
            height .45rem
            align-items center
            p:first-child
                width 2.42rem
                border-right 1px solid #ccc
                height 100%
                font-size .16rem
                background #EDF0F5
                display flex
                align-items center
                color #092d8e
                justify-content center
                border-left 1px solid #ccc
            .el-input
                border-right 1px solid #ccc
                flex 1
                height 100%
                input
                    border none
                    height .55rem
                    font-size .16rem
    .credit-evaluation-form-message
        width 100%
        h1
            border-left 1px solid #ccc
            width 2.4rem
            border-right 1px solid #ccc
            display flex
            align-items center
            justify-content center
            font-size .16rem
            height 100%
        p
            flex 1
            display flex
            height 100%
            align-items center
            justify-content center
            font-size .16rem
            border-right 1px solid #ccc
        .message-header
            height .45rem
            border-bottom 1px solid #ccc
            display flex
            h1, p
                background #EDF0F5
                color #092d8e
                font-weight 800
        .message-content
            display flex
            flex-direction column
            height 3.2rem;
            overflow-y auto;
            .message-content-div
                height .45rem
                border-bottom 1px solid #ccc
                display flex
                align-items center
                justify-content center
                .overflowHidden
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                .looker
                    cursor pointer
                    color #092d8e
                    text-decoration underline
               .el-input
                    width .6rem
                    input
                        padding 0
                        text-align center
                        font-size .16rem
                        line-height .28rem
                        height .28rem
                .beizhuinput
                    width 100%
                    .el-input
                        width 100%
                        input
                            padding 0
                            height .43rem
                            text-align left
                            font-size .16rem
                            border none
                            padding-left .2rem
        .message-bottom-button
            width 100%
            display flex
            justify-content center
            padding-bottom .4rem
            margin .2rem 0
            position absolute
            bottom 0
            left 0
            width 100%
            button
                width 2.5rem
                &:last-child
                    margin-left 1rem
</style>
